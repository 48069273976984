/* ----------------------------------------------------------------------------
custom properties
---------------------------------------------------------------------------- */
:root {
  /* color */
  --color-white: #ddd;
  --color-gray: #999;
  --color-black: #000;
  --color-text: var(--color-black);
  --color-sheet: var(--color-white);

  @media(prefers-color-scheme: dark) {
    --color-sheet: var(--color-gray);
  }

  /* type */
  --font-main: system-ui, -apple-system, blinkmacsystemfont, avenir next, avenir, segoe ui, sans-serif;
  --leading: 1.4;

  /* space */
  --space-base: 2rem;
  --space-scale: 2;
  --space-xxs: calc(var(--space-xs) / var(--space-scale));
  --space-xs: calc(var(--space-s) / var(--space-scale));
  --space-s: calc(var(--space-m) / var(--space-scale));
  --space-m: var(--space-base);
  --space-l: calc(var(--space-m) * var(--space-scale));
  --space-xl: calc(var(--space-l) * var(--space-scale));
  --space-xxl: calc(var(--space-xl) * var(--space-scale));
}
