

@keyframes fan-spin {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
}

@keyframes open-louvers {
  0% {
    transform: scaleY(100%);
  }
  50% {
    transform: scaleY(75%);
  }
  100% {
    transform: scaleY(50%);
  }
}

.fan {
  aspect-ratio: 1/1;
  width: min(400px, 80vw);
  height: auto;
  position: relative;

  & .frame {
    position: absolute;
    inset: 0;
    background-image: url("/assets/frame.gif");
    background-size: 100% 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
    image-rendering: pixelated;
    z-index: 3;
  }

  & .blades {
    z-index: 1;
    position: absolute;
    aspect-ratio: 1/1;
    inset: 0;
    background-image: url("/assets/blades.gif");
    background-size: 200% 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
    image-rendering: pixelated;

    &.--active {
      animation: fan-spin 0.1s step-start infinite;

      @media (prefers-reduced-motion) {
        animation: none;
      }
    }
  }

  & .louvers {
    z-index: 2;
    position: absolute;
    inset-block: 6.5%;
    inset-inline: 6.5%;
    display: flex;
    flex-direction: column;

    & .louver {
      position: relative;
      flex-grow: 1;
      background-color: var(--color-sheet);
      border: 2px solid var(--color-text);
      border-left: none !important;
      border-right: none !important;
      transition: all 0.1s steps(3, start);
      background-image: url("/assets/louvers.gif");
      background-size: 140%;
      background-position: 0 -1rem;
      background-repeat: no-repeat;
      image-rendering: pixelated;

      @nest .fan.--active & {
        margin-top: 10%;
        background-position: 0 0rem;
      }
    }
  }
}
