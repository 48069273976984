.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 2.5rem;
  color: var(--color-text);
  background-color: transparent;
  border: none;
  appearance: none;
  text-decoration: none;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  text-transform: uppercase;

  background-image: url("/assets/toggle.gif");
  background-size: 200% 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  image-rendering: pixelated;

  &.--active {
    background-position: 100% 0;
  }

  &.--active:before {
    left: auto;
    right: 0;
  }

  & > span {
    width: 3.125em;
    height: 2.5em;
    position: absolute;
    background-size: 100% 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
    image-rendering: pixelated;
  }

  & > span:first-child {
    left: -33%;
    background-image: url("/assets/off.gif");
  }

  & > span:last-child {
    right: -30%;
    background-image: url("/assets/on.gif");
  }
}

.button__icon {
  width: var(--space-l);
  height: var(--space-l);
  background-color: var(--color-text);
  clip-path: polygon(10% 0, 50% 25%, 90% 50%, 10% 100%);
  transform: translateX(0.25em);
  opacity: 0.7;
  transition: clip-path 0.2s ease, transform 0.2s ease, opacity 0.2s ease;

  @nest .button.--active & {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    transform: translateX(0);
    opacity: 0.8;
  }

  @media (hover: hover) {
    @nest .button:hover & {
      opacity: 0.8;
    }
  }
}
