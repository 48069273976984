*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::selection {
  color: var(--color-sheet);
  background-color: var(--color-text);
}

html {
  background-color: var(--color-sheet);
  overflow-x: hidden;
  overscroll-behavior: none;
}

body {
  overflow: hidden;
  overscroll-behavior: none;
  color: var(--color-text);
  background-color: var(--color-sheet);
  
}

button {
  font-family: var(--font-main);
  font-size: inherit;
}

sound-player {
  display: contents;
}
